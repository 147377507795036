function Button() {
  return (
    <div className="zoom-buttons">
      <button className="btn btn-outline-primary zoom-button" id="zoom-in">
        +
      </button>
      <button className="btn btn-outline-primary zoom-button" id="zoom-out">
        -
      </button>
    </div>
  );
}

export default Button;
