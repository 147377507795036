import avatarPersonnel from "./assets/avatar-personnel.svg";

/*
  node: {
    id: string,
    hasChild: boolean,
    splitChildren?: boolean,
    children: array[node],
    person: {
      name: string,
      title?: string,
      skills?: string,
      avatar?: Object,
      link?: string
      totalReports?: number,
    },
  }
*/

function getEmployeeNode(data) {
  const node = {
    id: data.name,
    hasChild: data.isManager,
    splitChildren: data.isManager,
    children: [],
    person: {
      name: data.name,
      title: data.isManager ? "Manager" : "",
      salary: data.salary,
      office: data.office,
      skills: data.skills.join(", "),
      avatar: avatarPersonnel,
    },
  };

  return node;
}

function getDeptNodes(depts) {
  return depts.map((dept) => {
    const employeeNodes = dept.employees.map(getEmployeeNode);
    const managerNode = employeeNodes.find(
      (e) => e.person.name === dept.managerName
    );

    let children;
    if (managerNode) {
      employeeNodes.splice(employeeNodes.indexOf(managerNode), 1);
      managerNode.person.totalReports = employeeNodes.length;
      managerNode.children = employeeNodes;
      children = [managerNode];
    } else {
      children = employeeNodes;
    }

    return {
      id: dept.name,
      hasChild: dept.employees.length > 0,
      children,
      person: {
        name: dept.name,
        title: "Department",
      },
    };
  });
}

export async function getData() {
  let data = JSON.parse(localStorage.getItem("organization-chart"));
  if (data == null) {
    data = await fetch("/organization-chart").then((res) => res.json());
    new Promise((resolve) => {
      localStorage.setItem("organization-chart", JSON.stringify(data));
      resolve();
    });
  }
  return data;
}

export function getTree(orgData) {
  return {
    id: "organization",
    hasChild: orgData != null,
    children:
      orgData == null ? [] : getDeptNodes(orgData.organization.departments),
    person: {
      name: "Awesome Organization",
      title: "Organization",
    },
  };
}

export const text = `Hello, world!
There are two highlights for this implementation.
First, you can hover the people and view their details (salary and office location in this case).
Second, as each department has too many employees to display in the same row, I separated them into rows of 3.

Enjoy!
`;
