import React from "react";
import "./App.css";
import {
  RouterProvider,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Main from "./Main";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route index path="/orgchart" element={<Main />} />
      <Route path="*" element={<Navigate to="/orgchart" replace />} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={router} />;
}
