import React from "react";
import OrgChart from "react-org-chart";
import Paper from "./Paper";
import Button from "./Button";
import { getData, getTree, text } from "./data";

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {},
      tree: getTree(),
      loading: true,
    };
  }

  componentDidMount() {
    document.title = 'Organization Chart';
    getData().then((data) => {
      this.setState({
        tree: getTree(data),
        loading: false,
      });
    });
  }

  handleLoadConfig = () => this.state.config;
  handleOnChangeConfig = (config) => {
    this.setState({ config: config });
  };

  render() {
    return (
      <>
        <Paper>{text}</Paper>
        <Button />
        {this.state.loading || (
          <OrgChart
            tree={this.state.tree}
            onConfigChange={this.handleOnChangeConfig}
            loadConfig={this.handleLoadConfig}
            loadImage={() => Promise.resolve(null)}
          />
        )}
      </>
    );
  }
}
